<template>
    <div id="users">
        <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>申请管理</el-breadcrumb-item>
            <el-breadcrumb-item>申请处理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">
                <el-form-item label="工厂">
                    <el-input v-model="queryMap.factoryName" placeholder="请输入工厂" clearable></el-input>
                </el-form-item>
                <el-form-item label="软件名">
                    <el-input
                            v-model="queryMap.name"
                            placeholder="请输入软件名"
                            clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="申请类型">
                    <el-select v-model="queryMap.type" placeholder="请选择" clearable>
                        <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审批状态">
                    <el-select v-model="queryMap.status" placeholder="请选择" clearable>
                        <el-option
                                v-for="item in optionss"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="期间">
                    <el-date-picker
                            @change="changeDate"
                            v-model="dateValue"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="margin-left:50px;">
                    <el-button type="warning" @click="reset" icon="el-icon-refresh">重置</el-button>
                    <el-button type="primary" @click="getApplicationProcessingList" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
            <!-- 表格区域 -->
            <el-table v-loading="loading" size="small" :data="applicationProcessingList" border style="width: 100%;"
                      height="600">
                <el-table-column label="No" width="50">
                    <template slot-scope="scope">
                        {{ scope.$index + (queryMap.currentPage - 1) * queryMap.pageSize + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="factoryName" width="150" show-overflow-tooltip label="工厂名"></el-table-column>
                <el-table-column prop="name" width="150" show-overflow-tooltip label="软件服务名"></el-table-column>
                <el-table-column prop="type" label="申请类型" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.type==0">试用</el-tag>
                        <el-tag type="success" v-if="scope.row.type==1">正式</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="applyDate" label="申请日" width="180"></el-table-column>
                <el-table-column prop="count" label="申请数量" width="80"></el-table-column>
                <el-table-column prop="endTime" label="使用期限" width="100"></el-table-column>
                <el-table-column prop="status" label="审批状态" width="130">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status==0">待管理员审核</el-tag>
                        <el-tag type="info" v-if="scope.row.status==1">待二次审核</el-tag>
                        <el-tag type="warning" v-if="scope.row.status==2">完成</el-tag>
                        <el-tag type="success" v-if="scope.row.status==3">密钥完成</el-tag>
                        <el-tag type="danger" v-if="scope.row.status==9">拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="ukeyStatus" label="密钥状态" width="100">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.license==null || scope.row.license==''">未作成</el-tag>
                        <el-tag type="success" v-if="scope.row.license!=null && scope.row.license!=''">已做成</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button size="small" type="warning"
                                   @click="approval(scope.row.id)">审批
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                    style="margin-top:10px;"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryMap.currentPage"
                    :page-sizes="[10, 20, 30]"
                    :page-size="queryMap.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            ></el-pagination>

            <el-dialog class="class_dialog_hospital" :title="dialogTitle" @close="closeDialog"
                       :visible.sync="DialogVisible"
                       width="100%;">
                <!-- 表单 -->
                <el-card class="box-card">
                    <span>
                        <el-form :model="addForm" :label-position="labelPosition" :rules="addFormRules" ref="addFormRef"
                                 label-width="90px">

                            <span>软件服务名：{{ addForm.name }}</span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;申请类型：
                              <span v-if="addForm.type== 0">试用</span>
                              <span v-if="addForm.type== 1">正式</span>
                            </span>
                            <p></p>
                            <span>工厂名：{{ addForm.factoryName }}</span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;申请日：{{ addForm.applyDate }}</span>
                            <p>申请数量：{{ addForm.count }}</p>
                            <span>使用日数：{{ addForm.probation }}</span>
                            <span v-if="addForm.status==2||addForm.status==3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用期限：{{ addForm.endTime }}</span>
                            <p></p>
                            <el-form-item label="使用期限" label-width="70px"
                                          v-if="addForm.type == 1 && (addForm.status==0||addForm.status==1 )">
                                  <el-date-picker
                                          @change="endTimeDate"
                                          v-model="addForm.endTime"
                                          type="date"
                                          value-format="yyyy-MM-dd"
                                          placeholder="选择日期">
                                  </el-date-picker>
                            </el-form-item>
                            <div style="display: flex;width: 70%;"><div style="flex: 0 0 70px">申请说明：</div><div>{{addForm.applyExplain}}</div></div>
                            <p></p>
                            <el-form-item label="批注" label-width="45px"
                                          v-if="userInfo.type==0&&(addForm.status==0||addForm.status==1)">
                                <el-input type="textarea" v-model="addForm.approve1Comment" maxlength="500" :rows:="5"
                                          :disabled="addForm.status===2||addForm.status===9"></el-input>
                            </el-form-item>
                            <el-form-item label="批注" label-width="45px"
                                          v-if="userInfo.type==2&&addForm.type!=0&&(addForm.status==1||addForm.status==2) ">
                                <el-input type="textarea" v-model="addForm.approve2Comment" maxlength="500" :rows="5"
                                          :disabled="addForm.type===0 || addForm.status===0 || addForm.status===9"></el-input>
                            </el-form-item>
                            <el-button size="small" v-if="userInfo.type==0" type="success"
                                       icon="el-icon-check"
                                       @click="editAdmin(addForm)"
                                       :disabled="addForm.status===2 || addForm.status===9 || addForm.status===2 || addForm.status===3">承认
                            </el-button>
                            <el-button size="small" type="primary" v-if="userInfo.type==2"
                                       icon="el-icon-check"
                                       @click="editMinister(addForm) "
                                       :disabled="addForm.type===0 || addForm.status===0 || addForm.status===9 || addForm.status===2 || addForm.status===3">
                              承认
                            </el-button>
                            <el-button type="danger" size="small" icon="el-icon-close" v-if="userInfo.type==0"
                                       @click="del(addForm)"
                                       :disabled="addForm.status===2 || addForm.status===9 || addForm.status===1 || addForm.status===3">否定
                            </el-button>
                            <el-button type="danger" size="small" icon="el-icon-close" v-if="userInfo.type==2"
                                       @click="del(addForm)"
                                       :disabled="addForm.status===2 || addForm.status===9 || addForm.type===0 || addForm.status===0 || addForm.status===3">
                              否定
                            </el-button>
                            <el-button size="small" type="warning"
                                       icon="el-icon-close"
                                       @click="closeDialog"
                            >取消
                            </el-button>
                        </el-form>
                        <hr style="margin-top: 10px">
                        <p style="font-weight: bold">承认履历</p>
                        <div>{{ addForm.userId }} 申请中&emsp; {{ addForm.createTime }}</div>
                        <div v-if="addForm.status==1||addForm.status==2||addForm.status==3">
                        <div>{{ addForm.approve1UserName }} 承认 &emsp;{{ addForm.approve1Date }}</div>
                        <div style="display: flex;width: 70%;">
                            <div style="flex: 0 0 70px">批注：</div>
                            <div>{{addForm.approve1Comment}}</div>
                        </div>
                        </div>
                        <div v-if="addForm.type!=0&&(addForm.status==2||addForm.status==3)">
                            <p v-if="addForm.approve2Date!=null">&emsp;&emsp;&emsp;↓</p>
                            <div v-if="addForm.approve2Date!=null">{{ addForm.approve2UserName }} 承认 &emsp;
                                {{ addForm.approve2Date }}
                            </div>
                            <div style="display: flex;width: 70%;">
                                <div style="flex: 0 0 70px">批注：</div>
                                <div>{{addForm.approve2Comment}}</div>
                            </div>
                        </div>
                    </span>
                </el-card>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
    import Password from "@/views/system/password.vue";

    export default {
        components: {Password},
        data() {
            return {
                DialogVisible: false,
                addFormRules: {},
                addForm: {},
                disabled: false,
                addDialogVisible: false,
                userInfo: {},
                dateValue: "",
                labelPosition: "right",
                options: [
                    {id: null, name: ""},
                    {id: 0, name: "试用"},
                    {id: 1, name: "正式"}
                ],
                optionss: [
                    {id: null, name: ""},
                    {id: 0, name: "申请中"},
                    {id: 1, name: "待审核"},
                    {id: 2, name: "处理中"},
                    {id: 3, name: "完成"},
                    {id: 4, name: "拒绝"}

                ],
                btnDisabled: false,
                loading: true,
                total: 0,
                //查询对象
                queryMap: {
                    startTime: "",
                    endTime: "",
                    currentPage: 1,
                    pageSize: 10,
                },
                applicationProcessingList: [],
                labelPosition: "right",
                dialogTitle: "",
                users: [],
                dialogType: ''
            };
        },
        methods: {


            /**
             *审批
             */
            async approval(id) {
                this.dialogType = "edit";
                this.DialogVisible = true;
                this.dialogTitle = "审批详细"
                const {data: res} = await this.$http.get("applicationProcessing/applicationProcessing/" + id);
                this.addForm = res.data;
                if (this.addForm.type == 0 && this.addForm.status == 0) {
                    this.addForm.endTime = this.dayjs().add(this.addForm.probation, 'day').format("YYYY-MM-DD")
                }
                for (let i = 0; i < this.users.length; i++) {

                    if (this.users[i].id == this.addForm.userId) {
                        this.addForm.updateUserName = this.users[i].name
                    }
                }
                if ((this.userInfo.type === 0 && this.addForm.status != 0) || (this.userInfo.type === 2 && this.addForm.status != 0)) {
                    this.disabled = true
                } else {
                    this.disabled = false
                }
                if (this.addForm.type == 1 && (this.addForm.status == 1 || this.addForm.status == 2)) {
                    this.endTimeDate(this.addForm.endTime)
                }
            },

            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    currentPage: 1,
                    pageSize: 10,
                };
                this.dateValue = ""
                this.getApplicationProcessingList();
                this.getUsers();
            },

            /**
             * 选择日期
             */
            changeDate(val) {
                if (val) {
                    this.queryMap.startDate = val[0];
                    this.queryMap.endTime = val[1];
                }
            },

            /**
             * 选择日期,计算使用天数
             */
            endTimeDate(value) {
                console.log(value)
                let date1 = new Date(value);
                //date2当前时间
                let date2 = new Date();

                date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
                date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
                const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
                const diffDate = diff / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
                this.addForm.probation = diffDate
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.$refs.addFormRef.clearValidate();
                this.DialogVisible = false;
                this.addForm = {};
            },

            /**
             * 加载列表
             */
            async getApplicationProcessingList() {
                const {data: res} = await this.$http.get("applicationProcessing/applicationProcessing", {
                    params: this.queryMap
                });
                if (!res.success) {
                    return this.$message.error("获取申请列表失败:" + res.data.errorMsg);
                }
                this.total = res.data.total;
                this.applicationProcessingList = res.data.list;
            },

            /**
             * 否定
             * @returns {Promise<void>}
             */
            async del(row) {
                this.addForm.id = row.id;
                const res = await this.$confirm(
                    "此操作将否定该申请, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                if (res === "confirm") {
                    const {data: res} = await this.$http.put("applicationProcessing/applicationProcessingNegation/" + row.id);
                    if (res.success) {
                        this.$notify.success({
                            title: '操作成功',
                            message: '否定成功',
                        });
                        this.DialogVisible = false;
                        this.getApplicationProcessingList();
                    } else {
                        this.$message.error(res.data.errorMsg);
                    }
                }
            },


            /**
             * 系统用户集合
             */
            async getUsers() {
                const {data: res} = await this.$http.get("/system/user/users");
                this.users = res.data;
            },

            /**
             * 部长审核
             * @returns {Promise<void>}
             */
            async editMinister(row) {
                this.addForm.id = row.id;
                const res = await this.$confirm(
                    "此操作将同意该申请, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                );
                if (res === "confirm") {
                    const {data: res} = await this.$http.put("applicationProcessing/applicationProcessingMinister/" + this.addForm.id, this.addForm);
                    if (res.success) {
                        this.$notify.success({
                            title: '操作成功',
                            message: '确认成功',
                        });
                        this.DialogVisible = false;
                        this.getApplicationProcessingList();
                    } else {
                        this.$message.error(res.data.errorMsg);
                    }
                }
            },

            /**
             * 管理员审核
             * @returns {Promise<void>}
             */
            async editAdminZero(row) {
                this.addForm.id = row.id;
                const res = await this.$confirm(
                    "此操作将同意该申请, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                if (res === "confirm") {
                    const {data: res} = await this.$http.put("applicationProcessing/applicationProcessingAdmin/" + this.addForm.id, this.addForm);
                    if (res.success) {
                        this.$notify.success({
                            title: '操作成功',
                            message: '确认成功',
                        });
                        this.DialogVisible = false;
                        this.getApplicationProcessingList();
                    } else {
                        this.$message.error(res.data.errorMsg);
                    }
                }
            },

            /**
             * 管理员审核操作
             * @returns {Promise<void>}
             */
            async editAdmin(row) {
                this.addForm.id = row.id;
                if (row.type == 0) {
                    this.editAdminZero(row);

                } else {
                    this.editAdminZero(row)

                }
            },

            /**
             *  改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;
                this.getApplicationProcessingList();
            },

            /**
             * 翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
                this.getApplicationProcessingList();
            },
        },


        created() {
            this.getApplicationProcessingList();
            this.getUsers();
            this.userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    };
</script>
<style>
    .set-content {
        cursor: default;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

</style>
